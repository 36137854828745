import React from "react";

const StatusTag = ({ status }) => {
  const tagClass = (() => {
    switch (status?.toLowerCase()) {
    case "passed":
      return "bg-status-tag-green text-white";
    case "failed":
      return "bg-status-tag-red text-white";
    case "retry":
      return "bg-yellow-200 text-yellow-900";
    case "running":
      return "bg-status-blue text-white";
    default:
      return "bg-gray-200 text-gray-800";
    }
  })();

  return (
    <>
      {status && <div className={`text-sm rounded-md py-1 font-bold text-center w-24 capitalize ${tagClass}`}>
        {status}
      </div>}
    </>
  );
};

export default StatusTag;
