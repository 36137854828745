import React from "react";

function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, function (match) {
      return match.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (match) {
      return match.toLowerCase();
    });
}

const FormLabel = ({ label }) => {
  // TODO move out to common file, share with other login form?
  return (
    <label htmlFor={toCamelCase(label)} style={{ marginBottom: 0 }}>
      {label}
    </label>
  );
};
export default FormLabel;
