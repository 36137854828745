import React from 'react'
import FormModalButton, { FormContext } from './form_modal_button'
import ToggleButton from 'react-toggle-button'
import {AsYouType} from 'libphonenumber-js'
import Select from 'react-select';
import "moment"
import moment from "moment-timezone"
import TimePicker from 'rc-time-picker'
import ScheduleCalendar from '../packs/schedule_calendar'
import DaySelector from '../packs/day_selector'
import './application_test_schedule.scss'
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";


export class ApplicationTestScheduleFormModalButton extends React.Component {

    render() {
        return (
            <FormModalButton {...this.props}
                type="Test Schedule"
                form_title={"Edit Test Schedule: " + this.props.model.id}
                action={`/application_test_schedules/${this.props.model.id}`}
            >
                <ApplicationTestSchedule {...this.props} />
            </FormModalButton>
        )
    }
}

export class ApplicationTestScheduleNewFormModalButton extends React.Component {

    render() {
        return (
            <FormModalButton {...this.props}
                type="Test Schedule"
                form_title={"New Test Schedule"}
                action={`/application_test_schedules`}
            >
                <ApplicationTestSchedule {...this.props} />
            </FormModalButton>
        )
    }
}

export class ApplicationTestSchedule extends React.Component {
    static contextType = FormContext
    constructor(props) {
        super();
        let org_tz = props.timezone || "America/Denver"
        moment.tz.setDefault(org_tz)
        let now = moment().tz(org_tz)

        let model = props.model || {
            frequency_period: "",
            frequency_quantity: 1,
            source: "+14243721500",
            endpoint: "",
            enabled: true,
            at: "",
            test_cases: [],
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
            all_day: true,
        };

        let endpoint = model.endpoint || ""
        let source = model.source || ""
        let validEndpoint = this.checkIfNumberisValid(endpoint)
        let validSource = this.checkIfNumberisValid(source)

        // Convert an array of test case IDs to an array of objects with label and value
        let allTestCaseOptions = this.testCaseOptions(props);
        let selectedTestCaseOptions = model.test_cases.map(testCase => {
            return allTestCaseOptions.find(option => option.value == testCase);
        }).filter(option => option); // Filters out nulls

        this.state = {
            id: model.id,
            name: model.name || "",
            description: model.description || "",
            frequency_period: model.frequency_period || "hour",
            frequency_quantity: model.frequency_quantity || 1,
            starting_at: model.starting_at && moment(moment(model.starting_at).tz('UTC').format('HH:mm'), "HH:mm") || moment("00:00", "HH:mm"),
            source: validSource.phoneNumber,
            endpoint: validEndpoint.phoneNumber,
            endpointValid: validEndpoint.isValid,
            sourceValid: validSource.isValid,
            scheduledTimeValid: true,
            enabled: model.enabled,
            at: model.at || "",
            test_cases: selectedTestCaseOptions,
            time_starts_at: model.time_starts_at ?
                moment(moment(model.time_starts_at).tz('UTC').format('HH:mm'), "HH:mm") :
                moment(now.clone().startOf('minute').format('HH:mm'), 'HH:mm'),
            time_ends_at: model.time_ends_at ?
                moment(moment(model.time_ends_at).tz('UTC').format('HH:mm'), 'HH:mm') :
                moment(now.clone().startOf('minute').add('5', 'minute').format('HH:mm'), 'HH:mm'),
            sunday: model.sunday || false,
            tuesday: model.tuesday || false,
            monday: model.monday || false,
            wednesday: model.wednesday || false,
            thursday: model.thursday || false,
            friday: model.friday || false,
            saturday: model.saturday || false,
            allDay: model.all_day || false,
            daysSelectedValid: true
        }

        this.nameChanged = this.nameChanged.bind(this);
        this.frequencyPeriodChanged = this.frequencyPeriodChanged.bind(this);
        this.frequencyQuantityChanged = this.frequencyQuantityChanged.bind(this);
        this.endpointChanged = this.endpointChanged.bind(this);
        this.sourceChanged = this.sourceChanged.bind(this);
        this.atChanged = this.atChanged.bind(this);
        this.scheduledTimeValidation = this.scheduledTimeValidation.bind(this);
        this.toggleFormButton = this.toggleFormButton.bind(this);
        this.startingAtChanged = this.startingAtChanged.bind(this);
    }

    componentDidMount() {
        if (!this.state.endpointValid || !this.state.sourceValid) {
            return this.context && this.context.disable()
        }
    }

    checkIfNumberisValid(number) {
        let phoneNumber;
        let isValid = false
        let phoneNumberToCheck = new AsYouType('US')
            phoneNumberToCheck.input(number)
        let validCheck = phoneNumberToCheck.isValid()

        if (validCheck) {
            isValid = true
            phoneNumber = phoneNumberToCheck.formattedOutput.replace(/\s/g, "")
        }

        return {
            isValid,
            phoneNumber
        }
    }

    enableButtonCheck() {
        if (this.state.endpointValid && this.state.sourceValid) {
            return true
        } else {
            return false
        }
    }


    toggleValidPhoneNumState(number, stateValid) {
        if(!number.isValid) {
            this.setState({[stateValid]: false}, this.toggleFormButton)

        } else {
            this.setState({[stateValid]: true}, this.toggleFormButton)
        }
    }

    nameChanged(event) {
        this.setState({
            name: event.target.value
        })
    }

    startingAtChanged(value) {
        let newTime = moment(value.format("HH:mm"), "HH:mm")
        this.setState({
            starting_at: newTime
        })
    }




    frequencyPeriodChanged(event) {
        this.setState({
            frequency_period: event.target.value
        })
    }

    frequencyQuantityChanged(event) {
        if (event.target.value != "" && (isNaN(event.target.value) || event.target.value < 1)) {
            return;
        }

        this.setState({
            frequency_quantity: event.target.value
        })
    }

    endpointChanged(event) {
        let endpointNum = this.checkIfNumberisValid(event.target.value)
        this.toggleValidPhoneNumState(endpointNum, "endpointValid")

        let phoneNumber = new AsYouType('US')
            phoneNumber.input(event.target.value.trim())
        let endpoint = (phoneNumber.getNumber() && phoneNumber.getNumber().number) || ""
        this.setState({
            endpoint
        })
    }

    sourceChanged(event) {
        let sourceNum = this.checkIfNumberisValid(event.target.value)
        this.toggleValidPhoneNumState(sourceNum, "sourceValid")

        let phoneNumber = new AsYouType('US')
            phoneNumber.input(event.target.value.trim())
        let source = (phoneNumber.getNumber() && phoneNumber.getNumber().number) || ""
        this.setState({
            source
        })
    }

    atChanged(event) {
        this.setState({
            at: event.target.value
        })
    }

    testCasesChanged(test_case_options) {
        this.setState({
            test_cases: (test_case_options || [])
        })
    }

    handleToggle(event){
        this.setState({enabled: !this.state.enabled})
    }

    renderEnabledToggle() {
        let width = "130px";
        let height = 30;
        let thumbHeight = 30 - 6;
        let activeColor = "#0073b7";

        return (
            <ToggleButton
                name={'application_test_schedule[enabled]'}
                value={ this.state.enabled || false }
                activeLabel={'ENABLED'}
                inactiveLabel={'DISABLED'}

                colors={{
                    active: {
                        base: activeColor
                    }
                }}

                containerStyle={{
                    width: width
                }}

                thumbStyle={{
                    borderRadius: 20,
                    width: '40px',
                    height: thumbHeight,
                    borderColor: 'transparent',
                    boxShadow: 'none'
                }}
                trackStyle={{
                    width: width,
                    height: height,
                    borderRadius: 20
                }}
                thumbAnimateRange={[3, 87]}
                activeLabelStyle=  {{ width:'50px', fontWeight: 'bold' }}
                inactiveLabelStyle={{ width:'50px', fontWeight: 'bold' }}
                onToggle={this.handleToggle.bind(this)}/>
        )
    }

    testCaseOptions(props) {
        let config = (props || this.props).application_test_configuration;
        if (!config) {
            return [];
        }
        let options = config.test_definition.map((testCase) => {
            return {
                value: testCase.id,
                label: testCase.name
            }
        });

        return options;
    }

    changeActiveDay(dayString) {
        this.setState({
            [dayString]: !this.state[dayString]
        }, () => {
            this.toggleFormButton()
        })
    }

    selectedDaysValidation() {
        let selectedDays = []

        let daysArray = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        for (let i = 0; i < daysArray.length; i++) {
            if (this.state[daysArray[i]]) {
                selectedDays.push(daysArray[i])
            }
        }

        if (selectedDays.length === 0 && !this.state.allDay) {
            this.setState({daysSelectedValid: false})
            return false
        }

        if (selectedDays.length === 0 && this.state.allDay) {
            this.setState({daysSelectedValid: false})
            return false
        } else {
            this.setState({daysSelectedValid: true})
            return true
        }
    }

    startTimeUpdated(value) {
        let newTime = moment(value.format("HH:mm"), "HH:mm")
        this.setState({
            time_starts_at: newTime
        }, () => {
           this.toggleFormButton()
        })
    }

    endTimeUpdated(value) {
        let newTime = moment(value.format("HH:mm"), "HH:mm")
        this.setState({
            time_ends_at: newTime
        }, () => {
            this.toggleFormButton()
        })
    }

    updateAllDay(event) {
        this.setState({
            allDay: event.target.checked
        }, () => {
            this.toggleFormButton()
        })
    }

    scheduledTimeValidation() {
        if (!moment(this.state.time_starts_at).isBefore(this.state.time_ends_at) && !this.state.allDay) {
            this.setState({scheduledTimeValid: false})
            return false
        } else {
            this.setState({scheduledTimeValid: true})
            return true
        }
    }

    isFormValid() {
        let valid = true
        valid = valid && this.scheduledTimeValidation()
        valid = valid && this.selectedDaysValidation()
        valid = valid && this.enableButtonCheck()
        return valid;
    }

    toggleFormButton() {
        if (this.isFormValid()) {
            return this.context && this.context.enable()
        } else {
            return this.context && this.context.disable()
        }
    }

    render() {
        let enabledToggle = this.renderEnabledToggle()
        let style = {
            timeZone: {
                fontSize: "14px",
                marginBottom: "8px"
            },
            grouping: {
                marginBottom: "16px"
            },
            allDayCheckbox: {
                marginLeft: "20px",
                verticalAlign: "top"
            }
        }
        let timeZoneDisplay = this.props.timezone || "America/Denver"
        let current_schedule_type
        let timeSection =
            <div>
                <label>Start Time</label>
                <TimePicker
                    className='ml-2'
                    showSecond={false}
                    defaultValue={this.state.time_starts_at}
                    onChange={this.startTimeUpdated.bind(this)}
                    format="h:mm A"
                    use12Hours
                    inputReadOnly
                    allowEmpty={false}
                    minuteStep={1}
                    id={`${this.state.scheduledTimeValid ? "" : "invalid"}`}
                />
                <label>End Time</label>

                <TimePicker
                    className='ml-2'
                    showSecond={false}
                    defaultValue={this.state.time_ends_at}
                    onChange={this.endTimeUpdated.bind(this)}
                    format="h:mm A"
                    use12Hours
                    inputReadOnly
                    allowEmpty={false}
                    minuteStep={1}
                />
                <br />
            </div>

            current_schedule_type =
                <div>
                    <div style={style.grouping}>
                        <hr/>
                        <div style={style.timeZone}>
                            Organization Timezone: {timeZoneDisplay}
                            <input
                                type="checkbox"
                                id="all-day"
                                name="all-day"
                                style={style.allDayCheckbox}
                                checked={this.state.allDay || false}
                                onChange={this.updateAllDay.bind(this)}
                            />
                            <label htmlFor="frequency-full-week" style={{marginLeft: "5px"}}>All Day</label>
                        </div>
                    {!this.state.allDay && timeSection}
                    </div>
                    <div>
                        <label>Days Scheduled</label>
                        <div className="weekday-holder" id={this.state.daysSelectedValid ? "" : "invalid"}>
                            {["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].map((key,index) => {
                                let value = this.state[key]
                                let classString = value ? "weekday active" : "weekday"
                                return <button type="button" key={index} className={classString} onClick={() => this.changeActiveDay(key)}>{key.charAt(0).toUpperCase() + key.charAt(1)}</button>
                            })}
                        </div>
                    </div>
                </div>

        return (
            <div>
                <input type="hidden" name="application_test_schedule[application_test_id]" value={this.props.application_test_id} />

                <div style={{marginBottom: "20px"}} className="row">
                    <div className="col-sm-6">
                        <label htmlFor="application_test_schedule[enabled]">
                            Enabled
                        </label>
                        <input type="text" name='application_test_schedule[enabled]' readOnly={true} style={{display: 'none'}} value={this.state.enabled}/>
                        {enabledToggle}
                    </div>
                </div>

                <div style={{marginBottom: "20px"}} className="row">

                    <div className="col-sm-4">
                        <label htmlFor="application_test_schedule[frequency_quantity]">
                            Every
                        </label>
                        <input type="text" id="application_test_schedule[frequency_quantity]" className="form-control"
                                disabled={this.props.readonly}
                                name={"application_test_schedule[frequency_quantity]"}
                                onChange={this.frequencyQuantityChanged}
                                value={this.state.frequency_quantity}
                                required={true}
                        />
                    </div>

                    <div className="col-sm-4">
                        <label htmlFor="application_test_schedule[frequency_period]">
                            period
                        </label>
                        <select type="text" id="application_test_schedule[frequency_period]" className="form-control"
                                disabled={this.props.readonly}
                                name={"application_test_schedule[frequency_period]"}
                                onChange={this.frequencyPeriodChanged}
                                value={this.state.frequency_period}
                        >
                            <option value="hour">hour{this.state.frequency_quantity > 1 && "s"}</option>
                            { this.props.allow_per_minute_scheduling && <option value="minute">minute{this.state.frequency_quantity > 1 && "s"}</option> }
                        </select>
                    </div>
                    { this.state.frequency_period == "hour" &&
                    this.state.frequency_quantity == 1 &&
                    <div className="col-sm-4">
                            <label htmlFor="application_test_schedule[at]">
                                at
                            </label>
                            <select type="text" id="application_test_schedule[at]" className="form-control"
                                    disabled={this.props.readonly}
                                    name="application_test_schedule[at]"
                                    onChange={this.atChanged}
                                    value={this.state.at}
                            >
                                <option value="**:00">:00</option>
                                <option value="**:01">:01</option>
                                <option value="**:02">:02</option>
                                <option value="**:03">:03</option>
                                <option value="**:04">:04</option>
                                <option value="**:05">:05</option>
                                <option value="**:06">:06</option>
                                <option value="**:07">:07</option>
                                <option value="**:08">:08</option>
                                <option value="**:09">:09</option>
                                <option value="**:10">:10</option>
                                <option value="**:11">:11</option>
                                <option value="**:12">:12</option>
                                <option value="**:13">:13</option>
                                <option value="**:14">:14</option>
                                <option value="**:15">:15</option>
                                <option value="**:16">:16</option>
                                <option value="**:17">:17</option>
                                <option value="**:18">:18</option>
                                <option value="**:19">:19</option>
                                <option value="**:20">:20</option>
                                <option value="**:21">:21</option>
                                <option value="**:22">:22</option>
                                <option value="**:23">:23</option>
                                <option value="**:24">:24</option>
                                <option value="**:25">:25</option>
                                <option value="**:26">:26</option>
                                <option value="**:27">:27</option>
                                <option value="**:28">:28</option>
                                <option value="**:29">:29</option>
                                <option value="**:30">:30</option>
                                <option value="**:31">:31</option>
                                <option value="**:32">:32</option>
                                <option value="**:33">:33</option>
                                <option value="**:34">:34</option>
                                <option value="**:35">:35</option>
                                <option value="**:36">:36</option>
                                <option value="**:37">:37</option>
                                <option value="**:38">:38</option>
                                <option value="**:39">:39</option>
                                <option value="**:40">:40</option>
                                <option value="**:41">:41</option>
                                <option value="**:42">:42</option>
                                <option value="**:43">:43</option>
                                <option value="**:44">:44</option>
                                <option value="**:45">:45</option>
                                <option value="**:46">:46</option>
                                <option value="**:47">:47</option>
                                <option value="**:48">:48</option>
                                <option value="**:49">:49</option>
                                <option value="**:50">:50</option>
                                <option value="**:51">:51</option>
                                <option value="**:52">:52</option>
                                <option value="**:53">:53</option>
                                <option value="**:54">:54</option>
                                <option value="**:55">:55</option>
                                <option value="**:56">:56</option>
                                <option value="**:57">:57</option>
                                <option value="**:58">:58</option>
                                <option value="**:59">:59</option>
                            </select>
                        </div>
                    }
                    { this.state.frequency_period == "hour" && this.state.frequency_quantity > 1  &&
                    <div className="col-sm-3">
                        <label>
                            starting at
                        </label>
                        <br/>
                        <TimePicker
                            value={this.state.starting_at}
                            showSecond={false}
                            onChange={this.startingAtChanged.bind(this)}
                            format="h:mm A"
                            use12Hours
                            inputReadOnly
                            allowEmpty={false}
                            minuteStep={1}
                        />
                    </div>
                    }


                </div>

                <div className="row mb-2">
                    <div className="col-sm-6">
                    <label htmlFor="application_test_schedule[source]">
                        from
                    </label>
                    <input type="text" id="application_test_schedule[source]" className={`form-control ${this.state.sourceValid ? "" : "invalid"}`}
                            disabled={this.props.readonly}
                            name={"application_test_schedule[source]"}
                            onChange={this.sourceChanged}
                            value={this.state.source}
                    />
                    </div>

                    <div className="col-sm-6">
                    <label htmlFor="application_test_schedule[endpoint]">
                        to
                    </label>
                    <input type="text" id="application_test_schedule[endpoint]" className={`form-control ${this.state.endpointValid ? "" : "invalid"}`}
                            disabled={this.props.readonly}
                            name={"application_test_schedule[endpoint]"}
                            onChange={this.endpointChanged}
                            value={this.state.endpoint}
                            placeholder="XXXXXXXXXX"
                    />
                    </div>

                </div>

                <div>
                    <label htmlFor="test_case-selector">Match Test Cases</label>
                    <OverlayTrigger trigger="click" placement="top" overlay={
                            <Popover id="test-case-selector-help">
                                <p>The test will pass if it matches <i>any</i> of the selected test cases.</p>

                                <p>If none are selected, the test will pass if it matches any configured test case, and will automatically include new test cases when they are configured.</p>
                            </Popover>
                        }>
                        <div className="helpButton-label helpButton-label--gray">
                            <i className="fas fa-question-circle"></i>
                        </div>
                    </OverlayTrigger>
                    <br/>
                    <Select
                        required
                        name="application_test_schedule[test_cases][]"
                        value={this.state.test_cases}
                        onChange={this.testCasesChanged.bind(this)}
                        backspaceRemovesValue={true}
                        id="test-case-selector"
                        isMulti={true}
                        isSearchable={false}
                        closeMenuOnSelect={false}
                        options={this.testCaseOptions.apply(this)}
                        components={{ IndicatorSeparator: null }}
                        classNamePrefix="Select-Component"
                        placeholder="Any Test Case"
                        noOptionsMessage={() => {
                            if (this.state.test_cases.length > 0) {
                                return "No remaining test cases"
                            } else {
                                return "No test cases configured"
                            }
                        }}

                        styles={{
                            clearIndicator: provided => ({
                                ...provided,
                                padding: "5px 0px",
                                width: "16px"
                            }),
                            dropdownIndicator: provided => ({
                                ...provided,
                                padding: "5px 4px 5px 0px"
                            })
                        }}
                    />
                    {current_schedule_type}
                </div>

                <div>
                    <input type="hidden" name="application_test_schedule[sunday]" value={this.state.sunday}/>
                    <input type="hidden" name="application_test_schedule[monday]" value={this.state.monday}/>
                    <input type="hidden" name="application_test_schedule[tuesday]" value={this.state.tuesday}/>
                    <input type="hidden" name="application_test_schedule[wednesday]" value={this.state.wednesday}/>
                    <input type="hidden" name="application_test_schedule[thursday]" value={this.state.thursday} />
                    <input type="hidden" name="application_test_schedule[friday]" value={this.state.friday}/>
                    <input type="hidden" name="application_test_schedule[saturday]" value={this.state.saturday}/>
                    <input type="hidden" name="application_test_schedule[time_starts_at]" value={this.state.time_starts_at.format("HH:mm:ss")}/>
                    <input type="hidden" name="application_test_schedule[time_ends_at]" value={this.state.time_ends_at.format("HH:mm:ss")}/>
                    <input type="hidden" name="application_test_schedule[all_day]" value={this.state.allDay}/>
                    <input type="hidden" name="application_test_schedule[starting_at]" value={this.state.starting_at.format("HH:mm:ss")}/>
                </div>

            </div>
        );
    }
}


