import React from "react";
import ToggleButton from "react-toggle-button";

export class ApplicationTestFormModalSimple extends React.Component {
  constructor(props) {
    super(props);

    let model = props.model || {};

    this.state = {
      showModal: true,
      installedApplications: [],
      name: model.name || "",
      enabled: model.enabled === false ? false : true,
      installed_application_id: model.installed_application_id || "",
      alarm_enabled: model.alarm_enabled === false ? false : true,
      alarm_severity: model.alarm_severity || "error",
      dead_air_timeout: model.dead_air_timeout || 12000,
      step_silence_timeout: model.step_silence_timeout || 12000,
    };
  }

  componentDidMount() {
    this.getApplications.bind(this)();
  }

  handleToggle() {
    this.setState({ enabled: !this.state.enabled });
  }

  handleChange(field, event) {
    let update = {};
    update[field] = event.target.value;
    this.setState(update);
  }

  getApplications() {
    let self = this;
    return $.ajax({
      method: "GET",
      url: "/installed_applications?application_type=IVR",
      success: function (data) {
        self.setState({ installedApplications: data });
      },
      error: function (err) {
        console.log("Failed to retrieve operating systems: ", err);
      },
    });
  }

  renderEnabledToggle(readOnly) {
    let width = "130px";
    let height = 30;
    let thumbHeight = 30 - 6;
    let activeColor = "#0073b7";

    return (
      
      <div id="enabled-toggle-container">
        <ToggleButton
          name={"application_test[enabled]"}
          disabled={readOnly} // the library doesn't actually support this, we fake it below
          value={this.state.enabled || false}
          activeLabel={"ENABLED"}
          inactiveLabel={"DISABLED"}
          colors={{
            active: {
              base: activeColor,
            },
          }}
          containerStyle={{
            display: "inline-block",
            width: width,
            opacity: readOnly ? 0.5 : 1,
          }}
          thumbStyle={{
            borderRadius: 20,
            width: "40px",
            height: thumbHeight,
            borderColor: "transparent",
            boxShadow: "none",
          }}
          trackStyle={{
            width: width,
            height: height,
            borderRadius: 20,
          }}
          thumbAnimateRange={[3, 87]}
          activeLabelStyle={{ width: "50px", fontWeight: "bold" }}
          inactiveLabelStyle={{ width: "50px", fontWeight: "bold" }}
          onToggle={() => {
            if (readOnly) return;
            this.handleToggle();
          }}
        />
      </div>
    );
  }

  render() {
    let readOnly = this.props.status == "show";
    let enabledToggle = this.renderEnabledToggle(readOnly);

    let applicationOptions = [
      <option key={-1} value={""}>
        None
      </option>,
    ];
    this.state.installedApplications.forEach(function (app, index) {
      applicationOptions.push(
        <option key={index} value={app.id}>
          {app.name}
        </option>
      );
    });

    return (
      <div>
        <input
          type="text"
          name="application_test[enabled]"
          style={{ display: "none" }}
          readOnly
          value={this.state.enabled}
        />
        {enabledToggle}
        <br />

        <br />
        <label htmlFor="application_test[name]">Name</label>
        <br />
        <input
          disabled={readOnly}
          type="text"
          name="application_test[name]"
          className="form-control"
          value={this.state.name || ""}
          onChange={this.handleChange.bind(this, "name")}
          required
        />

        <br />
        <label htmlFor="application_test[installed_application_id]">
          Application
        </label>
        <br />
        <select
          disabled={readOnly}
          name="application_test[installed_application_id]"
          value={this.state.installed_application_id}
          className="form-control"
          onChange={this.handleChange.bind(this, "installed_application_id")}
        >
          {applicationOptions}
        </select>

        <br />
        <label htmlFor="application_test[dead_air_timeout]">
          Dead Air Timeout (ms)
        </label>
        <br />
        <input
          disabled={readOnly}
          type="number"
          name="application_test[dead_air_timeout]"
          className="form-control"
          value={this.state.dead_air_timeout}
          onChange={this.handleChange.bind(this, "dead_air_timeout")}
          min="3000"
          max="60000"
          required
        />

        <br />
        <label htmlFor="application_test[step_silence_timeout]">
          Step Silence Timeout (ms)
        </label>
        <br />
        <input
          disabled={readOnly}
          type="number"
          name="application_test[step_silence_timeout]"
          className="form-control"
          value={this.state.step_silence_timeout}
          onChange={this.handleChange.bind(this, "step_silence_timeout")}
          min="3000"
          max="60000"
          required
        />

        <br />
        <input
          type="text"
          name="application_test[alarm_enabled]"
          style={{ display: "none" }}
          readOnly
          value={this.state.alarm_enabled}
        />
        <input
          type="checkbox"
          disabled={readOnly}
          id="application_test[alarm_enabled_checkbox]"
          checked={this.state.alarm_enabled || false}
          onChange={(event) => {
            this.setState({ alarm_enabled: event.target.checked });
          }}
        />
        <label
          style={{
            marginBottom: "10px",
            marginLeft: "8px",
            verticalAlign: "top",
            paddingTop: "2px",
          }}
          htmlFor="application_test[alarm_enabled_checkbox]"
        >
          Alarm Enabled
        </label>

        {this.state.alarm_enabled && (
          <div>
            <label htmlFor="application_test[alarm_severity]">Severity</label>
            <br />
            <select
              disabled={readOnly}
              name="application_test[alarm_severity]"
              value={this.state.alarm_severity}
              className="form-control"
              onChange={this.handleChange.bind(this, "alarm_severity")}
            >
              <option value="error">Error</option>
              <option value="warning">Warning</option>
              <option value="info">Info</option>
            </select>
          </div>
        )}
      </div>
    );
  }
}

export default ApplicationTestFormModalSimple;
