import React from 'react';
import {arrayMove, sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import { FormContext } from './form_modal_button';
import { CSSTransition } from 'react-transition-group'
import './application_test_configuration.scss';

const SortableItem = sortableElement(({children}) => {
    return <div className="row hover-container" style={{
        padding: '10px 0px 5px 0px',
        margin: '5px 0px 10px 0px',
        backgroundColor: '#eff3f7',
        borderRadius: '4px',
        zIndex: 1050
    }}>{children}</div>;
});
const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});
const DragHandle = sortableHandle(() => <i className="fas fa-grip-lines" style={{
        fontSize: '16px',
        color: '#808b9e'
    }}>
</i>);

export default class ApplicationTestConfiguration extends React.Component {
    static contextType = FormContext;

    constructor(props) {
        super(props);

        let model = this.props.model || { name: "", description: "", steps: [{
            match_type: "transcript",
            match: "",
            response: "",
            ref: uuidv4()
        }]};
        let steps = model.steps.map((step, i) => {
            let step_copy = JSON.parse(JSON.stringify(step));
            step_copy.ref = uuidv4();
            return step_copy
        })

        let result = this.checkStepsForErrors(steps);

        this.state = {
            steps: result.steps,
            hasErrors: result.hasErrors,
            id: model.id || uuidv4(),
            name: model.name || "",
            description: model.description || "",
            mode: false,
        }

        this.addStep = this.addStep.bind(this);
        this.onSortStart = this.onSortStart.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.descriptionChanged = this.descriptionChanged.bind(this);
        this.modeChanged = this.modeChanged.bind(this);

    }

    componentDidMount() {
        if (this.state.hasErrors && this.context) {
            this.context.disable();
        }
    }

    checkStepsForErrors(steps) {
        // loop through steps
        let hasErrors = false;
        for (let i = 0; i < steps.length; i++) {
            let step = steps[i];
            // check if this step has a match_type of "any" and is the last step
            if (step.match_type == "any" && i == steps.length - 1) {
                // if so, set step error
                step.error = "The last step can't have a match type of 'Any'";
                hasErrors = true;
            }
            // check if both this and the previous step had a match_type of "any"
            else if (step.match_type == "any" && i > 0 && steps[i-1].match_type == "any") {
                // if so, set step error
                step.error = "Can't have two consecutive steps with a match type of 'Any'";
                hasErrors = true;
            }
            else if (step.match_type == "transcript" && step.match == "") {
                step.error = "Match can't be blank";
                hasErrors = true;
            } else {
                delete step.error;
            }
        }

        return {
            steps: steps,
            hasErrors: hasErrors
        }
    }

    updateSteps(steps, cb) {

        let result = this.checkStepsForErrors(steps);

        if (result.hasErrors) {
            this.context && this.context.disable();
        } else {
            this.context && this.context.enable();
        }

        this.setState({
            steps: result.steps,
            hasErrors: result.hasErrors
        }, cb);
    }

    addStep() {
        let steps = this.state.steps
        steps.push({ match_type: "transcript", match: "", response: "", ref: uuidv4() });
        this.updateSteps(steps);
    }

    insertStepAt(i) {
        console.log("insert step at " + i);
        let steps = this.state.steps;
        steps.splice(i, 0, { match_type: "transcript", match: "", response: "", ref: uuidv4() });
        this.updateSteps(steps);
    }

    moveStepUp(stepNumber) {
        if (stepNumber == 1) {
            return;
        }

        let steps = this.state.steps;

        let temp = steps[stepNumber-2];
        steps[stepNumber-2] = steps[stepNumber-1];
        steps[stepNumber-1] = temp;

        this.updateSteps(steps);

        window.scrollTo(window.pageXOffset, window.pageYOffset - 149);
    }

    moveStepDown(stepNumber) {
        let steps = this.state.steps;

        if (stepNumber == steps.length) {
            return;
        }

        let temp = steps[stepNumber];
        steps[stepNumber] = steps[stepNumber-1];
        steps[stepNumber-1] = temp;

        this.updateSteps(steps);

        window.scrollTo(window.pageXOffset, window.pageYOffset + 149);
    }

    onSortStart() {
        window.document.body.style.cursor = 'grabbing';
        this.setState({
            dragging: true
        })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        // arrayMove is a built in function given to us from react-sortable-hoc
        let result = this.checkStepsForErrors(arrayMove(this.state.steps, oldIndex, newIndex));
        this.setState({
            dragging: false,
            steps: result.steps,
            hasErrors: result.hasErrors
        });
    }

    nameChanged(event) {
        this.setState({
            name: event.value
        })
    }

    descriptionChanged(event) {
        this.setState({
            description: event.value
        })
    }

    modeChanged(event) {
        this.setState({
            mode: event.target.checked
        })
    }

    matchTypeChanged(i, event) {
        let steps = this.state.steps;
        steps[i].match_type = event.target.value;

        this.updateSteps(steps);
    }

    matchChanged(i, event) {
        let steps = this.state.steps;
        let step = steps[i];
        step.match = event.target.value;

        this.updateSteps(steps);
    }

    matchLanguageChanged(i, event) {
        let steps = this.state.steps;
        let step = steps[i];
        step.match_language = event.target.value;

        this.updateSteps(steps);
    }

    responseChanged(i, event) {
        let steps = this.state.steps;
        let step = steps[i];
        step.response = event.target.value;

        this.updateSteps(steps);
    }

    responseTypeChanged(i, event) {
        let steps = this.state.steps;
        let step = steps[i];
        step.response_type = event.target.value;

        this.updateSteps(steps);
    }

    responseLanguageChanged(i, event) {
        let steps = this.state.steps;
        let step = steps[i];
        step.response_language = event.target.value;

        this.updateSteps(steps);
    }

    deleteStep(i) {
        let steps = this.state.steps;
        let step = steps[i];
        step.__deleting = true;
        step.__animating = true;
        this.setState({
            steps: steps
        });
    }

    stepAnimationOnEnter(i) {
        console.log("stepAnimationOnEnter:" + i)
        let step = this.state.steps[i];
        step.__entering = true;
        step.__animating = true;
        this.setState({
            steps: this.state.steps
        });
    }

    stepAnimationOnEntered(i) {
        console.log("stepAnimationOnEntered:" + i)
        let step = this.state.steps[i];
        step.__entering = false;
        step.__animating = false;
        this.setState({
            steps: this.state.steps
        });
    }

    stepAnimationOnExited(i) {
        console.log("stepAnimationOnExited:" + i)
        let steps = this.state.steps;
        steps.splice(i, 1);
        this.updateSteps(steps);
    }

    render() {
        // console.log("Loading test steps:", this.state.steps);

        let modeCheckBox = <div></div>;

        // if (this.props.status == "new") {
        //     modeCheckBox = <div>
        //         <br/>
        //         <label htmlFor="application_test_configuration[test_case][mode]">
        //             Run in Test Mode
        //         </label>
        //         <input type="checkbox" id={"test_case_mode"} //className="form-control"
        //             disabled={this.props.readonly}
        //             name={"application_test_configuration[test_case][mode]"} cols="50" rows="5"
        //             style={{marginTop: "5px", marginLeft: "5px"}}
        //             onChange={this.modeChanged}
        //             checked={this.state.mode}
        //         />
        //         </div>
        // }

        let mode = this.state.mode;
        let id = this.props.application_test_configuration && this.props.application_test_configuration.id

        return (
            <div>
                <input type="hidden" name="application_test_configuration[id]" value={id || undefined} />
                <input type="hidden" name="application_test_configuration[test_case][id]" value={this.state.id} />

                <div style={{marginBottom: "20px"}}>
                    <label htmlFor="application_test_configuration[test_case][name]">
                        Name
                    </label>
                    <input type="text" id="application_test_configuration[test_case][name]" className="form-control"
                            disabled={this.props.readonly}
                            name={"application_test_configuration[test_case][name]"} cols="50" rows="5"
                            onChange={this.nameChanged}
                            value={this.state.name}
                            required={true}
                        />

                    <br/>

                    <label htmlFor="application_test_configuration[test_case][description]">
                        Description
                    </label>
                    <input type="text" id={"test_case_description"} className="form-control"
                            disabled={this.props.readonly}
                            name={"application_test_configuration[test_case][description]"} cols="50" rows="5"
                            onChange={this.descriptionChanged}
                            value={this.state.description}
                        />
                    {modeCheckBox}
                </div>

                <SortableContainer onSortEnd={this.onSortEnd} onSortStart={this.onSortStart} useDragHandle>
                    {
                        this.state.steps.map((step, i) => {
                            let stepNum = i+1;
                            // const nodeRef = React.createRef();
                            return (<CSSTransition in={!step.__deleting} appear={this.state.steps.length > 1} classNames="test-step" key={step.ref}
                                timeout={{
                                    appear: 500,
                                    exit: 300
                                }}
                                onEnter={this.stepAnimationOnEnter.bind(this, i)}
                                onEntered={this.stepAnimationOnEntered.bind(this, i)}
                                onExited={this.stepAnimationOnExited.bind(this, i)}
                            >
                                <SortableItem index={i} key={step.ref}>
                                    <div>
                                        {!step.__animating && !this.props.readonly && !this.state.dragging &&
                                            <div className="show-on-container-hover col-sm-12" style={{
                                                position: 'relative',
                                                height: '0px',
                                            }}>
                                                <button type="button" id={`add-step-before-${i+1}`}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '75px',
                                                        top: '-29px',
                                                        right: '50%',
                                                        transform: 'translateX(50%)',
                                                        backgroundColor: 'rgb(211, 223, 236)',
                                                        padding: '0px 6px',
                                                        fontSize: '20px',
                                                        fontWeight: 'bold',
                                                        borderRadius: '20px',
                                                        textAlign: 'center',
                                                        opacity: 0.8,
                                                        cursor: 'pointer',
                                                        border: 0
                                                    }}
                                                        onClick={this.insertStepAt.bind(this, i)}
                                                    >
                                                    <span>＋</span>
                                                </button>
                                            </div>
                                        }

                                        <div style={{}} className="col-sm-8" >
                                            <span style={{
                                                marginRight: '10px',
                                                cursor: 'grab',
                                                verticalAlign: 'middle',
                                                display: this.props.readonly || mode ? 'none' : null,
                                                visibility: this.state.steps.length == 1 ? "hidden" : null
                                            }}>
                                                <DragHandle />
                                            </span>
                                            <label htmlFor={"test_definition_step_" + stepNum}>Step {stepNum}</label>

                                            <a className="show-on-container-hover"
                                                style={{
                                                    cursor: 'pointer',
                                                    marginLeft: '20px',
                                                    marginRight: '20px',
                                                    textDecoration: 'underline',
                                                    fontSize: '12px',
                                                    display: this.props.readonly ? "none" : null,
                                                    visibility: this.state.steps.length == 1 || this.state.dragging || step.__animating ? "hidden" : null
                                                }}
                                                onClick={this.deleteStep.bind(this, i)}
                                            >
                                                Delete
                                            </a>

                                            {step.error &&
                                                <span className="small-error">
                                                    {step.error}
                                                </span>
                                            }

                                            <textarea id="application_test_configuration[test_case][steps][][match]" className="form-control"
                                                style={{
                                                    border: 0,
                                                    resize: 'vertical',
                                                    backgroundColor: this.props.readonly || mode ? "#f2f5f9" : "#fff",
                                                    display: step.match_type == "any" ? 'none' : null
                                                }}
                                                disabled={this.props.readonly || mode}
                                                name="application_test_configuration[test_case][steps][][match]" cols="50" rows="5"
                                                onChange={this.matchChanged.bind(this, i)}
                                                value={step.match}
                                            />

                                            <div className="form-inline"style={{
                                                marginTop: '5px', marginBottom: '10px'
                                            }} >
                                                <label htmlFor={"test_definition_step_" + stepNum + "_match_type"}
                                                    style={{
                                                        margin: '10px 10px 10px 0px'
                                                    }}
                                                >
                                                    Match
                                                </label>
                                                <select type="text" id={"test_definition_step_" + stepNum + "_match_type"} className="form-control"
                                                    style={{
                                                        border: 0,
                                                        backgroundColor: this.props.readonly ? "#f2f5f9" : "#fff",
                                                        marginRight: '20px'
                                                    }}
                                                    disabled={this.props.readonly}
                                                    name="application_test_configuration[test_case][steps][][match_type]" cols="50" rows="5"
                                                    onChange={this.matchTypeChanged.bind(this, i)}
                                                    value={step.match_type || 'transcript'}
                                                >
                                                    <option value="transcript">Transcript</option>
                                                    <option value="any">Any</option>
                                                </select>

                                                <label htmlFor={"test_definition_step_" + stepNum + "_match_language"}
                                                    style={{
                                                        margin: '10px 10px 10px 0px',
                                                        display: step.match_type == "any" ? 'none' : null
                                                    }}
                                                >
                                                    Language
                                                </label>
                                                <select type="text" id={"test_definition_step_" + stepNum + "_match_language"} className="form-control"
                                                    style={{
                                                        border: 0,
                                                        backgroundColor: this.props.readonly ? "#f2f5f9" : "#fff",
                                                        display: step.match_type == "any" ? 'none' : null
                                                    }}
                                                    disabled={this.props.readonly}
                                                    name="application_test_configuration[test_case][steps][][match_language]" cols="50" rows="5"
                                                    onChange={this.matchLanguageChanged.bind(this, i)}
                                                    value={step.match_language || 'en-US'}
                                                >
                                                    <option value="en-US">English</option>
                                                    <option value="es-ES">Spanish</option>
                                                    <option value="ar-XA">Arabic</option>
                                                    <option value="cs-CZ">Czech</option>
                                                    <option value="da-DK">Danish</option>
                                                    <option value="nl-NL">Dutch</option>
                                                    <option value="fil-PH">Filipino</option>
                                                    <option value="fi-FI">Finnish</option>
                                                    <option value="fr-CA">French</option>
                                                    <option value="fr-FR">French</option>
                                                    <option value="de-DE">German</option>
                                                    <option value="el-GR">Greek</option>
                                                    <option value="hi-IN">Hindi</option>
                                                    <option value="hu-HU">Hungarian</option>
                                                    <option value="id-ID">Indonesian</option>
                                                    <option value="it-IT">Italian</option>
                                                    <option value="ja-JP">Japanese</option>
                                                    <option value="ko-KR">Korean</option>
                                                    <option value="cmn-CN">Mandarin Chinese</option>
                                                    <option value="nb-NO">Norwegian</option>
                                                    <option value="pl-PL">Polish</option>
                                                    <option value="pt-BR">Portuguese</option>
                                                    <option value="pt-PT">Portuguese</option>
                                                    <option value="ru-RU">Russian</option>
                                                    <option value="sk-SK">Slovak</option>
                                                    <option value="sv-SE">Swedish</option>
                                                    <option value="tr-TR">Turkish</option>
                                                    <option value="uk-UA">Ukrainian</option>
                                                    <option value="vi-VN">Vietnamese</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-sm-4" style={{
                                            verticalAlign: "top",
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                            display: step.match_type == "any" ? 'none' : null
                                        }}>
                                            <div style={{verticalAlign: "top"}} className="col-md-12">
                                                <label htmlFor={"test_definition_step_" + stepNum + "_response"}>Response</label>
                                                <input type="text" id={"test_definition_step_" + stepNum + "_response"} className="form-control"
                                                    style={{
                                                        border: 0,
                                                        backgroundColor: this.props.readonly ? "#f2f5f9" : "#fff",
                                                        margin: '5px 0px 10px 0px'
                                                    }}
                                                    disabled={this.props.readonly}
                                                    name="application_test_configuration[test_case][steps][][response]" cols="50" rows="5"
                                                    onChange={this.responseChanged.bind(this, i)}
                                                    value={step.response}
                                                />
                                            </div>
                                            <div style={{verticalAlign: "top", margin: '5px 0px 10px 0px'}} className="col-md-6">
                                                <label htmlFor={"test_definition_step_" + stepNum + "_response_type"}>Type</label>
                                                <select type="text" id={"test_definition_step_" + stepNum + "_response_type"} className="form-control"
                                                    style={{
                                                        border: 0,
                                                        backgroundColor: this.props.readonly ? "#f2f5f9" : "#fff"
                                                    }}
                                                    disabled={this.props.readonly}
                                                    name="application_test_configuration[test_case][steps][][response_type]" cols="50" rows="5"
                                                    onChange={this.responseTypeChanged.bind(this, i)}
                                                    value={step.response_type || "dtmf"}
                                                >
                                                    <option value="dtmf">DTMF</option>
                                                    <option value="voice">Voice</option>
                                                </select>
                                            </div>
                                            {step.response_type == 'voice' ?
                                                <div style={{verticalAlign: "top", margin: '5px 0px 10px 0px'}} className="col-md-6">
                                                    <label htmlFor={"test_definition_step_" + stepNum + "_response_language"}>
                                                        Language
                                                    </label>
                                                    <select type="text" id={"test_definition_step_" + stepNum + "_response_language"} className="form-control"
                                                        style={{
                                                            border: 0,
                                                            backgroundColor: this.props.readonly ? "#f2f5f9" : "#fff"
                                                        }}
                                                        disabled={this.props.readonly}
                                                        name="application_test_configuration[test_case][steps][][response_language]" cols="50" rows="5"
                                                        onChange={this.responseLanguageChanged.bind(this, i)}
                                                        value={step.response_language || 'en-US'}
                                                    >
                                                        <option value="en-US">English</option>
                                                        <option value="es-ES">Spanish</option>
                                                        <option value="ar-XA">Arabic</option>
                                                        <option value="cs-CZ">Czech</option>
                                                        <option value="da-DK">Danish</option>
                                                        <option value="nl-NL">Dutch</option>
                                                        <option value="fil-PH">Filipino</option>
                                                        <option value="fi-FI">Finnish</option>
                                                        <option value="fr-CA">French</option>
                                                        <option value="fr-FR">French</option>
                                                        <option value="de-DE">German</option>
                                                        <option value="el-GR">Greek</option>
                                                        <option value="hi-IN">Hindi</option>
                                                        <option value="hu-HU">Hungarian</option>
                                                        <option value="id-ID">Indonesian</option>
                                                        <option value="it-IT">Italian</option>
                                                        <option value="ja-JP">Japanese</option>
                                                        <option value="ko-KR">Korean</option>
                                                        <option value="cmn-CN">Mandarin Chinese</option>
                                                        <option value="nb-NO">Norwegian</option>
                                                        <option value="pl-PL">Polish</option>
                                                        <option value="pt-BR">Portuguese</option>
                                                        <option value="pt-PT">Portuguese</option>
                                                        <option value="ru-RU">Russian</option>
                                                        <option value="sk-SK">Slovak</option>
                                                        <option value="sv-SE">Swedish</option>
                                                        <option value="tr-TR">Turkish</option>
                                                        <option value="uk-UA">Ukrainian</option>
                                                        <option value="vi-VN">Vietnamese</option>
                                                    </select>
                                                </div>
                                            : null }
                                        </div>
                                        {!step.__animating && !this.props.readonly && !this.state.dragging &&
                                            <div className="show-on-container-hover col-sm-12" style={{
                                                position: 'relative',
                                                height: '0px',
                                            }}>
                                                <button type="button" id={`add-step-after-${i+1}`}
                                                    style={{
                                                        position: 'absolute',
                                                        width: '75px',
                                                        top: '-3px',
                                                        right: '50%',
                                                        transform: 'translateX(50%)',
                                                        backgroundColor: 'rgb(211, 223, 236)',
                                                        padding: '0px 6px',
                                                        fontSize: '20px',
                                                        fontWeight: 'bold',
                                                        borderRadius: '20px',
                                                        textAlign: 'center',
                                                        opacity: 0.8,
                                                        cursor: 'pointer',
                                                        border: 0
                                                    }}
                                                    onClick={this.insertStepAt.bind(this, i+1)}
                                                >
                                                    <span>＋</span>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </SortableItem>
                            </CSSTransition>
                            );
                        })
                    }
                </SortableContainer>
            </div>
        );
    }
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
