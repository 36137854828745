import React from "react";

const CallFlowTesterSelfServeConfirm = ({}) => {
  const redirectToDashboard = () => {
    location.replace("/application_tests");
  };
  return (
    <form className="flex flex-col gap-6 items-center w-full text-center">
      <div>Check your email before continuing</div>
    </form>
  );
};
export default CallFlowTesterSelfServeConfirm;
