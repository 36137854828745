import React, { useState, useRef } from "react";
import { ApplicationTestFormModalSimple } from "../../application_test_form_modal_simple";
import ApplicationTestLiveConfig from "../../application_test_live_config";
import VigilusButton from "../../button/vigilus-button";
import FormModalCustom from "../../form_modal_custom";
import { ApplicationTestSchedule } from "../../application_test_schedule";
import Toast from "../../toast/toast";

/*
TODO combine with New Test Modal and make into a flow
  1. Create test
  2. Live Config
  3. Hang up and save test case
  4. Create schedule
  5. Drop user off at test runs            
*/

const CallFlowTesterOnboardingFlowContainer = (props) => {
  const liveConfigRef = useRef(null);
  const [step, setStep] = useState(0);
  const [toast, setToast] = useState(null);
  const [testId, setTestId] = useState(null);

  const getSubmitButtonText = () => {
    switch (step) {
      case 1:
        return "Create Test";
      case 2:
        return "Save Config";
      case 3:
        return "Save Schedule";

      default:
        return null;
    }
  };

  const handleSubmit = async (data) => {
    switch (step) {
      case 1:
        return handleCreateTest(data);
      case 2:
        return handleCreateConfig(data);

      default:
        return null;
    }
  };

  const handleCreateTest = async (data) => {
    $.ajax({
      method: "POST",
      url: "/application_tests",
      data,
      success: function (response) {
        console.log("created test response: ", response);
        setTestId(response.id);
        setStep(2);
        setToast("Test created successfully! Now do live config!");
      },
      error: function (err) {
        console.log("error: ", err);
        setError(err.responseText);
      },
    });
  };

  const handleCreateConfig = async (data) => {
    if (!testId) return;
    $.ajax({
      method: "POST",
      url: `/application_tests/${testId}/test_cases`,
      data, // TODO pass in similar data to what goes to New Test Case form
      success: function (response) {
        console.log("config post response: ", response);
        setStep(3);
        setToast("Saved config! Now make a schedule!");
      },
      error: function (err) {
        console.log("error: ", err);
        setError(err.responseText);
      },
    });
  };

  const handleCreateSchedule = async (data) => {
    $.ajax({
      method: "POST",
      url: "/users/continue/",
      data: {
        "user[email]": data.email.toLowerCase(),
        authenticity_token: authenticity_token,
        commit: "Continue to login",
      },
      success: function (response) {
        // send user to next step
        location.replace(response.redirect_url);
      },
      error: function (err) {
        console.log("error: ", err);
        if (err.status === 422) {
          location.replace("/");
        } else {
          setError(err.responseText);
        }
      },
    });
  };

  return (
    <>
      <div className="flex items-center">
        <VigilusButton onClick={() => setStep(1)}>
          + Add Test (new combined flow)
        </VigilusButton>
        <FormModalCustom
          {...props}
          formAction="POST" // POST || PUT
          status={"New"}
          isOpen={step}
          formTitle="New Call Flow Test"
          submitButtonText={getSubmitButtonText()}
          currentStep={step}
          totalSteps={5}
          onClose={() => setStep(0)}
          onSubmit={(data) => handleSubmit(data, step)}
        >
          <>
            {step === 1 && <ApplicationTestFormModalSimple {...props} />}
            {step === 2 && (
              <ApplicationTestLiveConfig {...props} ref={liveConfigRef} />
            )}
            {step === 3 && <ApplicationTestSchedule {...props} />}{" "}
            {/* TODO add schedule to correct test */}
          </>
        </FormModalCustom>
      </div>
      {toast && (
        <Toast duration={5000} onClose={() => setToast(null)}>
          {toast}
        </Toast>
      )}
    </>
  );
};

export default CallFlowTesterOnboardingFlowContainer;
