import React, { useState } from "react";
import { mainSidebarRoutes } from "./routes/main-sidebar-routes";
import { testerSidebarRoutes } from "./routes/tester-sidebar-routes";
import { trunkReportOnlySidebarRoutes } from "./routes/trunk-sidebar-routes";

const Sidebar = ({
  controllerParams,
  applicationTypeParams,
  organizationUsersPath,
  variant,
  isMasterAdmin,
  testsEnabled,
  isProduction,
  featureFlagged,
  loggingEnabled,
  cceConfigLogEnabled,
  hasTrunkMonitoringEnabled,
  cdrLogsEnabled,
  canManageOrganization,
  hasIntegrationenEnabled,
  billingUiFeatureFlag,
  credentialsUiFeatureFlag,
  callFlowTesterOnly,
  url,
}) => {
  function getRoutes() {
    switch (variant) {
      case "main":
        return mainSidebarRoutes({
          controllerParams,
          applicationTypeParams,
          organizationUsersPath,
          variant,
          isMasterAdmin,
          testsEnabled,
          isProduction,
          featureFlagged,
          loggingEnabled,
          cceConfigLogEnabled,
          hasTrunkMonitoringEnabled,
          cdrLogsEnabled,
          canManageOrganization,
          hasIntegrationenEnabled,
          billingUiFeatureFlag,
          credentialsUiFeatureFlag,
          callFlowTesterOnly,
        });
      case "tester":
        return testerSidebarRoutes({
          isMasterAdmin,
          canManageOrganization,
          billingUiFeatureFlag,
          callFlowTesterOnly,
          featureFlagged,
          organizationUsersPath,
        });
      case "trunk_report":
        return trunkReportOnlySidebarRoutes();
      default:
        return null;
    }
  }

  const [submenuOpen, setSubmenuOpen] = useState();

  const handleOpenCloseSubmenu = (route) => {
    if (submenuOpen && submenuOpen.label === route.label) {
      setSubmenuOpen(null);
    } else {
      setSubmenuOpen(route);
    }
  };

  const isTopLevelRouteActive = (route) => {
    // don't highlight any side nav when clicking on top nav links
    if (controllerParams === "organizations" || controllerParams === "users")
      return false;

    // fix weird controllerParams mistmatch in trunk_monitoring/trunk_summary
    if (route.label === "Trunk Monitoring" && url === "/trunk_summary")
      return false;
    if (route.label === "Trunk Summary" && url === "/trunk_summary")
      return true;

    return (
      route.path.includes(controllerParams) ||
      // users, dashboard, devices->settings overrides due to path not matching params exactly
      (route.label === "Dashboard" && controllerParams === "dashboard") ||
      (route.label === "Users" && controllerParams === "organizations/users") ||
      (route.label === "Devices" && controllerParams === "devices")
    );
  };

  const isSubMenuOpen = (route) => {
    return route.children.some(
      (child) =>
        child.path === `/${controllerParams}` ||
        (route.label === "Applications" &&
          child.path.includes(controllerParams)) ||
        (submenuOpen && submenuOpen.label === route.label)
    );
  };

  const isSubmenuActive = (child) => {
    return (
      child.path === `/${controllerParams}` ||
      (applicationTypeParams &&
        child.path.includes(controllerParams) &&
        applicationTypeParams === child.label)
    );
  };

  return (
    <div className="main-sidebar w-full">
      {getRoutes().map((route) => (
        <div key={route.label}>
          {route.visible && (
            <a
              key={route.label}
              href={route.children ? "#" : route.path}
              className={`flex items-center gap-2 px-6 py-4 justify-between text-black hover:text-black ${
                isTopLevelRouteActive(route)
                  ? "border-right-4 border-[#535095] bg-[#edecf5]"
                  : "hover:bg-[#edecf5]"
              }`}
              onClick={() => handleOpenCloseSubmenu(route)}
            >
              <div className="flex items-center gap-2">
                {route.icon}
                {route.label}
              </div>
              {route.children && route.children.length > 0 ? (
                isSubMenuOpen(route) ? (
                  <i className="fa fa-angle-down pull-right"></i>
                ) : (
                  <i className="fa fa-angle-right pull-right"></i>
                )
              ) : (
                <></>
              )}
            </a>
          )}
          {route.visible && route.children && (
            <div
              className={`${
                isSubMenuOpen(route) ? "flex pl-8 flex-col gap-0" : "hidden"
              }`}
            >
              {route.children
                .filter((child) => child.visible)
                .map((child) => (
                  <a
                    key={route.label + route.path + "-" + child.label}
                    href={child.path}
                    className={`flex items-center gap-2 p-2 text-black hover:bg-[#edecf5] ${
                      isSubmenuActive(child)
                        ? "border-right-4 border-[#535095] bg-[#edecf5]"
                        : "hover:bg-[#edecf5]"
                    }`}
                  >
                    {child.icon} {child.label}
                  </a>
                ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default Sidebar;
